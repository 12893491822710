import { useAppSelector } from "../../store/hooks/app.hook";
import { useDispatch } from 'react-redux';
import { AppStateInterface } from "../../store/interfaces/appState.interface";
import { setContactMe } from "../../store/reducers/app.slice";
import Form from 'react-bootstrap/Form';
import thumbsUp from '../../assets/icons/thumbs-up.png';

export default function ContactMe() {

    const dispatch = useDispatch();
    const contactMe = useAppSelector((state: AppStateInterface) => state.app.contactMe);

    const handleSubmit = () => {

    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row my-4">
                <div className="col-md-6 pb-4">
                    <Form.Control required
                        value={contactMe.name} onChange={(e) => dispatch(setContactMe({ key: 'name', value: e.target.value }))}
                        type="text" placeholder='Name'
                    />
                </div>
                <div className="col-md-6 pb-4">
                    <Form.Control required
                        value={contactMe.email} onChange={(e) => dispatch(setContactMe({ key: 'email', value: e.target.value }))}
                        type="email" placeholder='Email'
                    />
                </div>
                <div className="col-md-12 pb-4">
                    <Form.Control required
                        value={contactMe.subject} onChange={(e) => dispatch(setContactMe({ key: 'subject', value: e.target.value }))}
                        type="subject" placeholder='Subject'
                    />
                </div>

                <div className="col-md-12 pb-4">
                    <Form.Control as="textarea" rows={6} required
                        value={contactMe.message} onChange={(e) => dispatch(setContactMe({ key: 'message', value: e.target.value }))}
                        placeholder='Message'
                    />
                </div>
                <div className="col-md-12 text-end">
                    <button type="submit" className='tf-btn-primary-sm mt-2'>Send
                        <img src={thumbsUp} className='ms-2' alt="Thumbs up" width="24" height="24" />
                    </button>
                </div>
            </div>
        </form>
    )
}
