
import { AppModes } from '../../store/enums/actionModes.enum';
import { useAppSelector } from '../../store/hooks/app.hook';
import { AppStateInterface } from '../../store/interfaces/appState.interface';

export default function CloseIcon() {
    const mode = useAppSelector((state: AppStateInterface) => state.app.mode);
    return (<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.542023 29.1396C-0.180674 28.4169 -0.180674 27.2452 0.542023 26.5225L25.8408 1.22366C26.5635 0.500966 27.7353 0.500966 28.4579 1.22366C29.1806 1.94636 29.1806 3.11808 28.4579 3.84078L3.15914 29.1396C2.43644 29.8623 1.26472 29.8623 0.542023 29.1396Z" fill={mode === AppModes.DARK ? 'white' : 'black'} />
        <path d="M28.458 29.1396C27.7353 29.8623 26.5636 29.8623 25.8409 29.1396L0.542053 3.8408C-0.180644 3.11811 -0.180644 1.94638 0.542053 1.22369C1.26475 0.500988 2.43647 0.500988 3.15917 1.22369L28.458 26.5225C29.1807 27.2452 29.1807 28.4169 28.458 29.1396Z" fill={mode === AppModes.DARK ? 'white' : 'black'} />
    </svg>
    )
}