import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { AppStateInterface } from './store/interfaces/appState.interface';
import { useAppSelector } from './store/hooks/app.hook';
import { AppModes } from './store/enums/actionModes.enum';
import LandingPage from './components/LandingPage/LandingPage';

function App() {
    useAppSelector((state: AppStateInterface) => {
        document.body.classList.remove(AppModes.DARK);
        document.body.classList.remove(AppModes.LIGHT);
        document.body.classList.add(state.app.mode);
        return state.app.mode
    })
    return (
        <Router>
            <Switch>
                <Route path="/">
                    <LandingPage />
                </Route>
            </Switch>
        </Router>
    );
}
export default App;
