import styles from './LandingPage.module.scss';
import Navbar from '../Shared/NavBar';
import myLocation from '../../assets/location.png';
import iconLinkedin from '../../assets/icons/linkedin.png';
import iconInstagram from '../../assets/icons/instagram.png';
import iconTwitter from '../../assets/icons/twitter.png';
import iconFacebook from '../../assets/icons/facebook.png';
import brandqrcodes from '../../assets/brandqrcodes.com.png';
import urllist from '../../assets/urllist.net.png';
import designsai from '../../assets/designs.ai.png';
import linkprofile from '../../assets/linkprofile.png';
import thumbsUp from '../../assets/icons/thumbs-up.png';
import paperClip from '../../assets/icons/paperclip.png';
import projectBrandqrcodes from '../../assets/projects/brandqrcodes.com.png';
import projectLinkProfile from '../../assets/projects/link-profiles.png';
import projectUrllist from '../../assets/projects/urllist.net.png';
import projectDAI from '../../assets/projects/designs.ai.png';

import { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIcon from '../Shared/CloseIcon';
import logoFileFolder from '../../assets/icons/open-file-folder.png';
import jayResume from '../../assets/Jay_Resume2023_v3.png';
import logoEnvelope from '../../assets/icons/envelope.png';
import ContactMe from '../ContactMe/ContactMe';

export default function LandingPage() {

    const [showProjectDAIOpen, setShowProjectDAIOpen] = useState(false);
    const [showMoreAboutMeOpen, setShowMoreAboutMeOpen] = useState(false);
    const [showResumeModalOpen, setShowResumeModalOpen] = useState(false);
    // const [showAllPortfolioOpen, setShowAllPortfolioOpen] = useState(false);
    const [showContactMeModalOpen, setShowContactMeModalOpen] = useState(false);
    const [showProjectUrllistOpen, setShowProjectUrllistOpen] = useState(false);
    const [showProjectLinkProfileOpen, setShowProjectLinkProfileOpen] = useState(false);
    const [showProjectBrandqrcodesOpen, setShowProjectBrandqrcodesOpen] = useState(false);

    return (
        <div className="container-fluid">
            <div className={`container ${styles.homePageWrapper}`}>
                <Navbar onResumeClick={setShowResumeModalOpen} onContactMeClick={setShowContactMeModalOpen} />
                <div className='row mt-4'>
                    <div className="col-md-5">
                        <div className="tf-container p-4 animated fadeInDownBig delay-1">
                            <div className={styles.whatIDo}>
                                I Help Develop Your Ideas From Visual Concept To An Enterprise Product.
                            </div>
                            {/* <button className='tf-btn-primary mt-2' onClick={() => setShowContactMeModalOpen(!showContactMeModalOpen)}>Hire Me
                                <img src={thumbsUp} className='ms-2' alt="Thumbs up" width="24" height="27" />
                            </button> */}
                            <button className='tf-btn-primary mt-2' onClick={() => window.open('mailto:jaybee8989@yahoo.com', 'mail')}>Hire Me
                                <img src={thumbsUp} className='ms-2' alt="Thumbs up" width="24" height="27" />
                            </button>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-6 animated fadeInDownBig delay-2">
                                <div className={`col-md-12 mb-4 mb-md-4 ${styles.tfBox} ${styles.tfBox1}`}>
                                    <div className="fs-1 fw-bold">10+</div>
                                    <div className="mt-n3 fw-lighter">years experience</div>
                                </div>
                            </div>
                            <div className="col-md-6 animated fadeInDownBig delay-3">
                                <div className={`col-md-12 mb-4 mb-md-4 ${styles.tfBox} ${styles.tfBox2}`}>
                                    <div className="fs-1 fw-bold">72+</div>
                                    <div className="mt-n3 fw-lighter">handled projects</div>
                                </div>
                            </div>
                            <div className="col-md-6 animated fadeInDownBig delay-4">
                                <div className={`col-md-12 mb-4 mb-md-4  ${styles.tfBox} ${styles.tfBox3}`}>
                                    <div className="fs-1 fw-bold">30+</div>
                                    <div className="mt-n3 fw-lighter">happy clients</div>
                                </div>
                            </div>
                            <div className="col-md-6 animated fadeInDownBig delay-5">
                                <div className={`col-md-12 mb-4 mb-md-4 ${styles.tfBox} ${styles.tfBox4}`}>
                                    <div className="fs-1 fw-bold">20+</div>
                                    <div className="mt-n3 fw-lighter">deployed APIs</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`col-md-3 animated fadeInDownBig delay-6 mb-4 mb-md-0 ${styles.tfMe}`}></div>
                    <div className="col-md-4">
                        <div className="tf-container animated fadeInDownBig delay-7 mb-4 p-4">
                            <div className="d-flex bd-highlight">
                                <div className={`bd-highlight fw-lighter small tf-faded-caption`}>Name</div>
                                <div className="ms-auto bd-highlight fw-bolder">Jay Ademola</div>
                            </div>
                        </div>

                        <div className="tf-container animated fadeInDownBig delay-8 mb-4 p-4">
                            <div className="d-flex bd-highlight">
                                <div className={`bd-highlight fw-lighter small tf-faded-caption`}>Location</div>
                                <div className="ms-auto bd-highlight fw-bolder">Manchester, UK</div>
                            </div>
                            <img src={myLocation} alt="Location" width="100%" height="233px" />
                        </div>

                        <div className="tf-container animated fadeInDownBig delay-9 mb-4">
                            <div className="row">
                                <div className={`col-3`}>
                                    <a href='http://lpl.urllist.net/linkedIn_jayAdemola' target='_blank' className="tf-xsm-container" rel="noreferrer">
                                        <img src={iconLinkedin} alt="" width="24" height="24" />
                                    </a>
                                </div>
                                <div className={`col-3`}>
                                    <a href='http://lpl.urllist.net/ig_crazielips' target='_blank' className="tf-xsm-container" rel="noreferrer">
                                        <img src={iconInstagram} alt="" width="24" height="24" />
                                    </a>
                                </div>
                                <div className={`col-3`}>
                                    <a href='http://lpl.urllist.net/twitter_crazielips' target='_blank' className="tf-xsm-container" rel="noreferrer">
                                        <img src={iconTwitter} alt="" width="30" height="24" />
                                    </a>
                                </div>
                                <div className={`col-3`}>
                                    <a href='http://lpl.urllist.net/fb_crazielips' target='_blank' className="tf-xsm-container" rel="noreferrer">
                                        <img src={iconFacebook} alt="" width="15" height="24" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='row pb-5'>
                    <div className="col-md-7 mb-4 mb-md-0 animated fadeInDownBig delay-10">
                        <div className="tf-container pt-4">
                            <div className="row m-0">
                                <div className="col-md-12 mb-4 ">
                                    <div className="d-flex bd-highlight">
                                        <div className={`bd-highlight fw-bolder`}>Recent projects</div>
                                        {/* <div onClick={() => setShowAllPortfolioOpen(!showAllPortfolioOpen)} className={`ms-auto bd-highlight mouse-pointer fw-lighter small`}>See all</div> */}
                                    </div>
                                </div>

                                <div className="col-md-3 mb-4">
                                    <div className="tf-container tf-bg-darker tf-portfolio ms-0 mouse-pointer" onClick={() => setShowProjectBrandqrcodesOpen(!showProjectBrandqrcodesOpen)}>
                                        <img src={brandqrcodes} alt="" width="100%" height="100%" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="tf-container tf-bg-darker tf-portfolio ms-0 mouse-pointer" onClick={() => setShowProjectLinkProfileOpen(!showProjectLinkProfileOpen)}>
                                        <img src={linkprofile} alt="" width="100%" height="100%" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="tf-container tf-bg-darker pt-4 tf-portfolio ms-0 mouse-pointer" onClick={() => setShowProjectUrllistOpen(!showProjectUrllistOpen)}>
                                        <img src={urllist} alt="" width="100%" height="100%" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="tf-container tf-bg-darker pt-4 tf-portfolio ms-0 mouse-pointer" onClick={() => setShowProjectDAIOpen(!showProjectDAIOpen)}>
                                        <img src={designsai} className='mt-2 tf-radius-small' alt="" width="100%" height="100%" />
                                    </div>
                                </div>
                                {
                                    // showAllPortfolioOpen ? <Fragment>
                                    //     <div className="col-md-3">Fifth</div>
                                    // </Fragment> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 animated fadeInDownBig delay-11">
                        <div className="tf-container p-4">
                            <div className="d-flex bd-highlight">
                                <div className={`bd-highlight fw-bolder`}>About</div>
                                <div onClick={() => setShowResumeModalOpen(!showResumeModalOpen)} className={`ms-auto bd-highlight mouse-pointer fw-lighter small tf-faded-caption`}>Resume</div>
                            </div>
                            <div className='pt-4'>
                                <p>
                                    I’m a highly experienced software engineer with over 10 years of experience in the field. Throughout my career, I have worked on a wide range of projects, from small-scale web applications to large-scale enterprise systems. My expertise lies in full-stack development, with a particular focus on back-end development and server-less architectures.
                                </p>
                                {
                                    showMoreAboutMeOpen ? <Fragment>
                                        <p>
                                            I am a passionate server-less enthusiast with over 5 years of experience in the field of cloud computing. My expertise lies in developing highly scalable, cost-effective, and efficient server-less architectures using AWS Lambda. I am committed to the server-less paradigm and its benefits, including reduced infrastructure costs, better scalability, and faster time-to-market. I have extensive experience in designing and implementing server-less architectures for complex business applications, including event-driven architectures, micro-services, and API Gateway integrations.
                                        </p>
                                        <p>
                                            I have a deep understanding of cloud computing principles and a strong background in software development using a variety of programming languages such as Node.js, JavaScript, and PHP. I am also skilled in using server-less frameworks such as the Serverless Framework and the AWS Server-less Application Model (SAM).
                                            Throughout my career, I have worked on several server-less projects, including server-less applications for IoT, real-time data processing, and machine learning. I am a strong advocate for server-less and have delivered numerous talks and workshops on the topic.
                                            In addition to my technical skills, I am also a strong communicator and team player. I enjoy collaborating with other developers, designers, and stakeholders to ensure that projects are delivered on time and to the highest possible standard.
                                        </p>
                                    </Fragment> : null
                                }
                                <div onClick={() => setShowMoreAboutMeOpen(!showMoreAboutMeOpen)} className={`mt-2 bd-highlight mouse-pointer fw-lighter small tf-faded-caption`}>Show {!showMoreAboutMeOpen ? 'more' : 'less'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="custom-map-modal"
                size="lg"
                show={showResumeModalOpen}
                onHide={() => setShowResumeModalOpen(false)}
                animation={true}
            >
                <Modal.Header className='px-4 pt-4'>
                    <Modal.Title>
                        <img src={logoFileFolder} className='me-3' alt="Resume Icon" width="24" height="24" />
                        Resume
                    </Modal.Title>
                    <div className="float-end mouse-pointer" onClick={() => setShowResumeModalOpen(false)}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <img src={jayResume} alt="Jay Ademola Resume" width="100%" height="100%" />
                </Modal.Body>
            </Modal>

            <Modal className="custom-map-modal"
                show={showContactMeModalOpen}
                onHide={() => setShowContactMeModalOpen(false)}
                animation={true}
            >
                <Modal.Header className='px-4 pt-4'>
                    <Modal.Title>
                        <img src={logoEnvelope} className='me-3' alt="Email Icon" width="24" height="24" />
                        Email me
                    </Modal.Title>
                    <div className="float-end mouse-pointer" onClick={() => setShowContactMeModalOpen(false)}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <p>
                        Freelance opportunities, especially those involving ambitious or sizable tasks, fascinate me. Use the form nonetheless if you have any additional requests or inquiries.
                    </p>
                    <ContactMe />
                </Modal.Body>
            </Modal>

            <Modal className="custom-map-modal"
                size='xl'
                show={showProjectBrandqrcodesOpen}
                onHide={() => setShowProjectBrandqrcodesOpen(false)}
                animation={true}
            >
                <Modal.Header className='px-4 pt-4'>
                    <Modal.Title>
                        <a href='https://brandqrcodes.com' target='_blank' rel="noreferrer">
                            <img src={paperClip} className='me-3' alt="Paperclip Icon" width="24" height="24" />
                            brandqrcodes.com
                        </a>
                    </Modal.Title>
                    <div className="float-end mouse-pointer" onClick={() => setShowProjectBrandqrcodesOpen(false)}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <p>
                        Create a personal or commercial website to share anything, from job postings, social media links, business page, vcards, to image galleries.
                    </p>
                    <img src={projectBrandqrcodes} className='tf-radius' alt="Project brandqrcodes.com" width="100%" height="100%" />
                </Modal.Body>
            </Modal>

            <Modal className="custom-map-modal"
                size='xl'
                show={showProjectLinkProfileOpen}
                onHide={() => setShowProjectLinkProfileOpen(false)}
                animation={true}
            >
                <Modal.Header className='px-4 pt-4'>
                    <Modal.Title>
                        <a href='https://urllist.net/features/link-profiles' target='_blank' rel="noreferrer">
                            <img src={paperClip} className='me-3' alt="Paperclip Icon" width="24" height="24" />
                            Link Profile
                        </a>
                    </Modal.Title>
                    <div className="float-end mouse-pointer" onClick={() => setShowProjectLinkProfileOpen(false)}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <p>
                        Link profile holds the links to your social media, website, latest blog post, youtube page, email address and even your online shop - anywhere your audience is. All in one place.
                    </p>
                    <img src={projectLinkProfile} className='tf-radius' alt="Project urllist.net/features/link-profiles.com" width="100%" height="100%" />
                </Modal.Body>
            </Modal>

            <Modal className="custom-map-modal"
                size='xl'
                show={showProjectUrllistOpen}
                onHide={() => setShowProjectUrllistOpen(false)}
                animation={true}
            >
                <Modal.Header className='px-4 pt-4'>
                    <Modal.Title>
                        <a href='https://urllist.net' target='_blank' rel="noreferrer">
                            <img src={paperClip} className='me-3' alt="Papperclip Icon" width="24" height="24" />
                            urllist.net
                        </a>
                    </Modal.Title>
                    <div className="float-end mouse-pointer" onClick={() => setShowProjectUrllistOpen(false)}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <p>
                        Create trackable links and improve your marketing processes - all with analytics
                    </p>
                    <img src={projectUrllist} className='tf-radius' alt="Project urllist.net" width="100%" height="100%" />
                </Modal.Body>
            </Modal>

            <Modal className="custom-map-modal"
                size='xl'
                show={showProjectDAIOpen}
                onHide={() => setShowProjectDAIOpen(false)}
                animation={true}
            >
                <Modal.Header className='px-4 pt-4'>
                    <Modal.Title>
                        <a href='https://designs.ai' target='_blank' rel="noreferrer">
                            <img src={paperClip} className='me-3' alt="Papperclip Icon" width="24" height="24" />
                            designs.ai
                        </a>
                    </Modal.Title>
                    <div className="float-end mouse-pointer" onClick={() => setShowProjectDAIOpen(false)}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <p>
                        Create logos, videos, banners, mockups with A.I. in 2 minutes
                    </p>
                    <img src={projectDAI} className='tf-radius' alt="Project designs.ai" width="100%" height="100%" />
                </Modal.Body>
            </Modal>
        </div>
    )
}
