import { useState } from 'react';
import { Link } from "react-router-dom";
import logoDarkMode from '../../assets/logo-white.png';
import logoWhiteMode from '../../assets/logo-black.png';
import logoEnvelope from '../../assets/icons/envelope.png';
import logoModeSwitchDARK from '../../assets/icons/icons8-sun-48.png';
import logoModeSwitchLIGHT from '../../assets/icons/icons8-moon-64.png';
import logoFileFolder from '../../assets/icons/open-file-folder.png';

import { Collapse } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setMode } from '../../store/reducers/app.slice';
import { AppModes } from '../../store/enums/actionModes.enum';
import { useAppSelector } from '../../store/hooks/app.hook';
import { AppStateInterface } from '../../store/interfaces/appState.interface';
import CloseIcon from './CloseIcon';

interface NavBarProps {
    onResumeClick: Function;
    onContactMeClick: Function;
}

export default function Navbar(props: NavBarProps) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const mode = useAppSelector((state: AppStateInterface) => state.app.mode);
    const toggleMode = () => dispatch(setMode(mode === AppModes.LIGHT ? AppModes.DARK : AppModes.LIGHT));

    return (
        <div className="pt-5">
            <div className="tf-container animated fadeInDownBig delay-0 p-4">
                <div className="d-flex">
                    <div>
                        <Link to="/">
                            <img src={mode === AppModes.DARK ? logoDarkMode : logoWhiteMode} className='mt-1' alt="TheFunbi.com Logo" width="125" height="35" />
                        </Link>
                    </div>
                    <div className="ms-auto p-2 mouse-pointer" onClick={() => setOpen(!open)}
                        aria-controls="navCollapseContent"
                        aria-expanded={open}>
                        {
                            open ? <CloseIcon /> :
                                <svg width="47" height="22" viewBox="0 0 47 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47 2.35412C47 1.15432 46.0136 0.181702 44.7969 0.181702H2.20312C0.986374 0.181702 0 1.15432 0 2.35412C0 3.55391 0.986374 4.52653 2.20312 4.52653H44.7969C46.0136 4.52653 47 3.55391 47 2.35412Z" fill={mode === AppModes.DARK ? 'white' : 'black'} />
                                    <path d="M47 10.6817C47 9.48191 46.0136 8.50929 44.7969 8.50929H14.6875C13.4707 8.50929 12.4844 9.48191 12.4844 10.6817C12.4844 11.8815 13.4707 12.8541 14.6875 12.8541H44.7969C46.0136 12.8541 47 11.8815 47 10.6817Z" fill={mode === AppModes.DARK ? 'white' : 'black'} />
                                    <path d="M47 19.0093C47 17.8095 46.0136 16.8369 44.7969 16.8369H26.4375C25.2207 16.8369 24.2344 17.8095 24.2344 19.0093C24.2344 20.2091 25.2207 21.1817 26.4375 21.1817H44.7969C46.0136 21.1817 47 20.2091 47 19.0093Z" fill={mode === AppModes.DARK ? 'white' : 'black'} />
                                </svg>
                        }
                    </div>
                </div>

                <Collapse in={open}>
                    <div className="mt-3" id="navCollapseContent">
                        <div className="d-flex flex-wrap flex-row-reverse bd-highlight">
                            <div className="tf-sm-container mouse-pointer" onClick={() => toggleMode()}>
                                <img src={mode === AppModes.DARK ? logoModeSwitchDARK : logoModeSwitchLIGHT} alt="Mode Switch Icon" width="24" height="24" />
                            </div>
                            {/* <div className="tf-sm-container mouse-pointer" onClick={() => props.onContactMeClick(true)}>
                                <img src={logoEnvelope} className='me-3' alt="Email Icon" width="24" height="24" />
                                Contact me
                            </div> */}
                            <div className="tf-sm-container mouse-pointer" onClick={() => window.open('mailto:jaybee8989@yahoo.com', 'mail')}>
                                <img src={logoEnvelope} className='me-3' alt="Email Icon" width="24" height="24" />
                                Contact me
                            </div>
                            <div className="tf-sm-container mouse-pointer" onClick={() => props.onResumeClick(true)}>
                                <img src={logoFileFolder} className='me-3' alt="Resume Icon" width="24" height="24" />
                                Resume
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}